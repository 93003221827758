globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {
  "id": "13e3c29712b4593e3404131e407199efae2fc47a"
};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import { getEnv, getRelease, isProduction } from '@motimate/utils';
import * as Sentry from '@sentry/nextjs';
import UserAgent from '@/lib/user-agent';
var SENTRY_DSN = process.env.SENTRY_DSN;
Sentry.init({
  enabled: Boolean(SENTRY_DSN),
  dsn: SENTRY_DSN,
  environment: getEnv(),
  tracesSampleRate: isProduction() ? 0.001 : 0,
  maxValueLength: 2048,
  normalizeDepth: 10,
  release: getRelease(),
  sampleRate: 1,
  tunnel: '/tunnel',
  integrations: function integrations(defaultIntegrations) {
    // Workaround for
    // https://github.com/getsentry/sentry-javascript/issues/3388
    var isAffectByIssue3388 = window.navigator.userAgent.includes('Chrome/74.0.3729');
    var newIntegrations = defaultIntegrations.map(function (integration) {
      if (integration.name === 'TryCatch') {
        return Sentry.browserApiErrorsIntegration({
          requestAnimationFrame: !isAffectByIssue3388
        });
      }
      return integration;
    });
    // End of workaround

    newIntegrations.push(Sentry.extraErrorDataIntegration({
      depth: 5
    }));
    return newIntegrations;
  },
  ignoreErrors: [/Cannot read property 'regions' of undefined/, /document\.getElementsByClassName\("docs-homescreen-gb-container"\)\[0\]\.style/, /ResizeObserver loop limit exceeded/,
  // Email link Microsoft Outlook crawler compatibility error
  // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
  /Non-Error promise rejection captured with value\: Object Not Found Matching Id\:/,
  // Bing Instant Search error in Edge on iOS
  // https://stackoverflow.com/questions/69261499/what-is-instantsearchsdkjsbridgeclearhighlight
  /Can't find variable\: instantSearchSDKJSBridgeClearHighlight/, /window\.instantSearchSDKJSBridge\.onTextSelected is not a function/],
  beforeSend: function beforeSend(event) {
    if (UserAgent.isErrorSampling()) {
      return event;
    }
    return null;
  }
});